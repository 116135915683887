import React from 'react';
import ResourceArea from '../ResourceArea/ResourceArea';
import ResourceEvents from '../ResourceEvents/ResourceEvents';
import ResourceSongs from '../ResourceSongs/ResourceSongs';
import './Resources.css';

const Resources = ({ eventsData }) => {
  const [activeDir, setActiveDir] = React.useState('events')
  const changeActiveDir = (dir) => {
    setActiveDir(dir)
  }
  console.log(eventsData)
  return (
    <div className="Resources p-0">
      <div className='resources-wrapper row col-lg-10 col-12 mx-auto'>
        {(() => {
          switch (activeDir) {
            case 'events':
              return <ResourceEvents changeActiveDir={changeActiveDir} eventsData={eventsData} />
            case 'songs':
              return <ResourceSongs changeActiveDir={changeActiveDir} />
            case 'resourcesArea':
              return <ResourceArea changeActiveDir={changeActiveDir} />
            default:
              return null
          }
        })()}
      </div>
    </div>
  )
}

export default Resources;
