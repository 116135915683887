import React from 'react';
import PropTypes from 'prop-types';
import './EventInfo.css';

const EventInfo = () => {
  const [activeTab, setActiveTab] = React.useState('overview')
  return (
    <div className="EventInfo px-0">
      <div className='event-info-content-wrapper text-center px-3'>


        <div className='row col-lg-12 col-12 m-auto'>

          <div className=''>
            <div className='tabs my-4 mx-auto row col-lg-10 col-12'>
              <div className={activeTab == 'overview' ? 'event-tab-item active col-lg-2 col-6' : 'event-tab-item col-lg-2 col-6'} onClick={() => setActiveTab('overview')}>Overview</div>
              <div className={activeTab == 'dates' ? 'event-tab-item active col-lg-3 col-6' : 'event-tab-item col-lg-3 col-6'} onClick={() => setActiveTab('dates')}>Dates & Fee</div>
              <div className={activeTab == 'accomodation' ? 'event-tab-item active col-lg-3 col-12' : 'event-tab-item col-lg-3 col-12'} onClick={() => setActiveTab('accomodation')}>Accommodation</div>
              <div className={activeTab == 'guidelines' ? 'event-tab-item active col-lg-2 col-6' : 'event-tab-item col-lg-2 col-6'} onClick={() => setActiveTab('guidelines')}>Guidelines</div>
              <div className={activeTab == 'faq' ? 'event-tab-item active col-lg-2 col-6' : 'event-tab-item col-lg-2 col-6'} onClick={() => setActiveTab('faq')}>FAQs</div>
            </div>
          </div>



          {/* Overview Section Start */}
          <div className={activeTab == 'overview' ? 'row col-lg-12 px-0 mx-auto' : 'd-none'}>
            <div className='row col-lg-12 event-logo-wrapper mx-0'><img className='event-logo mx-auto' src={require('../../assets/lsm_logo.png')} /></div>
            <p className='my-0 sub-text'>A nation-wide two-day convention to celebrate choral music at its best</p>
            <div className='row col-lg-12 mx-auto my-4'>
              <div className='col-lg-4 col-12'>
                <div className='features-card my-2 px-2'>300+ voices</div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className='features-card my-2 px-2'>Professional Orchestra</div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className='features-card my-2 px-2'>Vocal Workshops and more</div>
              </div>
            </div>
            <div>
              <h4 className='sub-text my-0 mt-4'>JANUARY 27th (FRI) & 28th (SAT), 2023 | Coimbatore, Tamil Nadu, India</h4>
            </div>

            <div className='col-lg-9 col-12 mx-auto my-3 mt-4'>
              <iframe
                width="100%"
                height="480"
                src={`https://www.youtube.com/embed/QZBWWgPj7ss`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>

            <hr className='event-hr col-lg-9 col-11 my-4' />
            <div className='row m-auto col-lg-5 col-12 testimonial-item d-block my-2'>
              <div className='avatar-wrapper col-lg-12 col-12'>
                <img className='testimonial-avatar' src={require("../../assets/augustine-paul.jpeg")} />
              </div>
              <div className='col-lg-12 col-12 testimonial-content'>
                <h5 className='testimonial-title text-center mt-3'>Augustine Paul</h5>
                <h5 className='testimonial-designation text-center'>Conductor, The Madras Musical Association Choir</h5>
                <p className='testimonial-desc mt-4'>“My fond memories of grand Choral music are from the UK when our choir was part of the church music festival at Coventry. The choir was 400-strong with a full orchestra and organ. Great music and memorable fellowship with high energy concerts. Used to wonder when this will happen in India.</p>
                <p className='testimonial-desc'>Praise be to God, it’s happening in Coimbatore now.I am happy to be a part of this gathering to sing Messiah, the most performed work across the world.”</p>
                <p className='testimonial-desc'>The CCC has made a mark in the Indian Choral music scene and I am sure this event will be a great blessing. I have, over the years, enjoyed working with CCC. Looking forward to meeting you lovely people in January 2023.”</p>
              </div>
            </div>

            <div className='row m-auto col-lg-5 col-12 testimonial-item my-2'>
              <div className='avatar-wrapper col-lg-12 col-12'>
                <img className='testimonial-avatar' src={require("../../assets/4.jpg")} />
              </div>
              <div className='col-lg-12 col-12 testimonial-content'>
                <h5 className='testimonial-title text-center mt-3'>Faith Ragland</h5>
                <h5 className='testimonial-designation text-center'>Conductor, Coimbatore Chamber Chorale</h5>
                <p className='testimonial-desc mt-4'>“I take great pleasure in inviting you to a first-of-its-kind 2 days National Level Choral Festival hosted by Coimbatore Chamber Chorale (CCC) in our city.

                  As a young boy growing up in the 80s in a musically dry town in South Tamilnadu, I used to watch and listen to Young Messiah VCDs and audio tapes and was greatly inspired. I never had the opportunity to sing in a choir and used to stand infront of a mirror and conduct as the tape played.

                  As my musical journey continued and progressed, great mentors have encouraged and given me opportunities to sing and conduct Messiah and other classical masterpieces.

                  As a gesture of gratitude, CCC would like to provide a platform for aspiring choristers to sing with a professional choir and orchestra, meet musicians across India and celebrate 2 days of choral music at its best.

                  Do join us in making music and memories together. Looking forward to your participation and patronage.”</p>
              </div>
            </div>

            <div className='row col-lg-10 col-12 mx-auto'>
              <p className='invite-text my-0 mt-4 p-0'><label>You are invited! Come, sing with a 300 + choir, 40-piece orchestra, renowned soloists, conductors and be a part of a national-wide 2-day choir convention, Let's Sing Messiah, organized by the Coimbatore Chamber Chorale (CCC).</label></p>

              <div className='convention-aims-section my-3'>
                <label className='my-2 mx-4'>The convention aims at:</label>
                <ul>
                  <li className='my-2'>Unifying choirs and choristers and create an opportunity to sing classical repertoire with a full-fledged choir and a professional orchestra.</li>
                  <li className='my-2'>Helping musicians, singers, and choirs - network for meaningful connections and further widen the scope of choral community in India</li>
                </ul>
              </div>

              <div className='who-can-register-section'>
                <label className='mb-2'>Who Can Register?</label>
                <p className='m-0'>There is no musical prerequisite to register for this convention.  You will be expected to rehearse your part to prepare for
                  the convention and concert on your own. If needed, sectional online rehearsals will be organized and be intimated.</p>
              </div>


              <div className='pre-convention-workshop-section my-3'>
                <div className='d-flex align-items-center justify-content-between'>
                  <label className='mb-2'>How does this work?</label>
                </div>
                <ul>
                  <li className='my-2'>Register for the convention using the Register Button on top of this page</li>
                  <li className='my-2'>Practice materials will be shared with the registerd participants</li>
                  <li className='my-2'>Rehearse your section (S|A|T|B) on your own, or with your registered friends or choir</li>
                  <li className='my-2'>Join us for the combined rehearsals on 27th and 28th of January, 2023 in Coimbatore, TN</li>
                </ul>
              </div>

              <div className='pre-convention-workshop-section my-3'>
                <div className='d-flex align-items-center justify-content-between'><label>Pre-Convention Choral Singing Workshop</label></div>
                <p className='my-0 mt-2 content-para'>An optional pre-convention choral singing workshop is planned on <b>26th January 2023</b>, Thursday at 6.30 PM. This will be
                  an opportunity to learn from seasoned musicians and explore new ways to improve your choral
                  skills.</p>
                <p className='my-0 mt-2 content-para'>Interested Individuals can click on the box ‘Interested in the Pre-Convention choral singing Workshop’ while
                  filling the registration form. </p>
                <p className='my-0 mt-2 content-para'>More details and the fee cap would be intimated to you at a later stage.</p>
              </div>
            </div>

          </div>
          {/* Overview Section End */}



          {/* Dates Section Start */}
          <div className={activeTab == 'dates' ? 'row col-lg-10 mx-auto col-12 px-0' : 'd-none'}>
            <div className='fee-header col-lg-3 col-11 mx-auto'>LSM Registration Fee</div>
            <div className='fee-detail-section row col-lg-12 col-12 justify-content-center mx-auto px-0'>
              <div className='row col-lg-6 col-12 my-2 mx-2 px-0'>
                <div className='col-lg-12'>
                  <div className='col-lg-12 fee-detail-item text-center px-2 h-100'>
                    <div><h3 className='early-bird-reg m-0'>Before September 30th, 2022</h3></div>
                    <div className='tag'>₹2000</div>
                  </div>
                </div>
              </div>
              <div className='row col-lg-6 col-12 my-2'>
                <div className='fee-detail-item flex-column col-lg-12'>
                  <div className='col-lg-12 fee-detail-item text-center p-0'>
                    <div><h3 className='early-bird-reg special-discount m-0'>Special Student Discount *</h3></div>
                    <div>
                      <div className='tag'>₹1500</div>
                      <div className='discount-price-striked'><label>₹2000</label></div>
                    </div>
                  </div>
                  <div className='discount-info'>
                    <p className='m-0'>◉ To avail student discount, Use the checkbox in the registration form.</p>
                    <p className='m-0'>◉ It is mandatory to send the scanned copy of the college ID proof and age proof to <b>singmessiah2023@gmail.com</b> within 24 hours of registration.</p>
                    <p className='m-0'> ◉ Limited seats are available under student discount.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 col-12 px-0'>
                <div className='fee-footer col-lg-3 col-11 mx-auto'><div>Last Date for Registration</div><div>September 30th, 2022</div></div>
              </div>
              <img className='event-info-image-layout' src={require('../../assets/lsm_includes_excludes.png')} />
              <img className='event-info-image-layout' src={require('../../assets/lsm_time_date.png')} />
            </div>

            {/* <p className='mt-4 sub-text'>PROGRAM DATES & SCHEDULE</p>
            <p className='my-0 mt-2 content-para'><b>27th Jan 2023 </b> </p>
            <p className='my-0 mt-4 content-para'>
              <ul>
                <li>Morning (9AM- 12 PM) Singing Workshop Cum Rehearsal</li>
                <li>Evening (4PM-7PM) Full Rehearsal</li>
                <li>Evening (7.30 PM) Gala Dinner and Entertainment</li>
              </ul>
            </p>
            <p className='my-0 mt-2 content-para'><b>28th Jan 2023 </b> </p>
            <p className='my-0 mt-4 content-para'>
              <ul>
                <li>Morning – 9AM -12 PM - Final Rehearsal </li>
                <li>Evening – 5PM Onwards – Let’s Sing Messiah Concert</li>
              </ul>
            </p> */}

            <img className='mt-4 mx-auto event-info-image-layout schedule-banner' src={require('../../assets/lsm_timeline_mobile.png')} />
          </div>
          {/* Dates Section End */}

          {/* Accomodation Section Start */}
          <div className={activeTab == 'accomodation' ? 'row col-lg-10 mx-auto col-12 px-0' : 'd-none'}>
            <p className='my-0 mt-2 content-para'>For your convenience and comfort, we have partnered with Impact Travel & Tours, Coimbatore to make your stay/accommodation and transport within the city on the 27th & 28th of January 2023 as smooth as possible and without hassles.</p>

            <p className='my-0 mt-2 content-para'>The hotel prices and other related tariffs of transport within the city would be discussed with you by our travel partner, on enquiry. For hotel accommodation, we have double and single occupancy with a price range starting from Rs.3500/- to Rs.7500/-</p>

            <p className='my-0 mt-2 content-para'>If your budget is lower than the above slab for hotel accommodation, our travel partner can give you details of other hotels based on your requirement.</p>

            <p className='my-0 mt-2 content-para'>For accommodation - (single occupancy, double occupancy, or group occupancy), pick-up and drop from Airport (or) Railway station, in-city logistics. </p>

            <div className='col-lg-12 my-3'>
              <div className='col-lg-4 mx-auto'>
                <p className='my-0 mt-2 content-para contact-impact'>Kindly contact Impact Travels:</p>
                <div className='features-card col-lg-12 col-12 my-2 px-2'>+91 94433 77328</div>

                <div className='features-card col-lg-12 col-12 my-2 px-2'>+91 94875 77328</div>
              </div>
            </div>

            <h4 className='my-4'>If necessary, Impact Travels can also help you book your Air, Train or Bus-tickets.</h4>

            <h4 className='sub-head-text'>Note: We request you to book hotels and rooms in advance to avoid last minute inconveniences.</h4>

            <div className='nearest-attractions col-lg-12 my-2'>
              <h4 className='my-4'>NEAREST ATTRACTIONS</h4>
              <div className='attraction-item'>
                <h5>Ooty/Coonoor</h5>
                <div className='attraction-item-gallery row col-lg-12 col-12'>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/ooty_1.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/ooty_2.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/ooty_3.jpg')} />
                  </div>
                </div>
                <p className='my-0 my-4 content-para'>Ooty, the gem among southern hill region is covered in eucalyptus, pine trees, coffee and tea plantations. On a clear day, it is possible to see as far as the Mysore plateau from Dodabetta Peak, the district's most prominent viewpoint. The Stone House, a landmark 1822 bungalow, and St. Stephen's Church are remnants of the area's first British settlement. Also noteworthy: formal botanical gardens, a children's mini-garden and a contemporary art collection.</p>
              </div>

              <div className='attraction-item'>
                <h5>Kodaikanal</h5>
                <div className='attraction-item-gallery row col-lg-12 col-12'>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/kod_1.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/kod_2.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/kod_3.jpg')} />
                  </div>
                </div>
                <p className='my-0 my-4 content-para'>Perched on the woodsy southern crest of the Palani Hills, nearly 7,000 feet above sea level, this popular lakeside resort town is a magnet for nature lovers. Wander the peaceful slopes, keeping an eye out for birds, exotic flowers and, of course, grizzled giant squirrels.</p>
              </div>

              <div className='attraction-item'>
                <h5>Valparai (Day tour)</h5>
                <div className='attraction-item-gallery row col-lg-12 col-12'>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/valparai_1.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/valparai_2.jpg')} />
                  </div>
                  <div className='attraction-item-gallery-item col-lg-4 col-12'>
                    <img className='gallery-img' src={require('../../assets/valparai_3.jpg')} />
                  </div>
                </div>
                <p className='my-0 my-4 content-para'>Valparai is called “7th Heaven”, This pollution free land is located above 3,500 feet from the sea level on the Anamalai mountain range. Valparai stands majestically with Green Spread Mountains and forest all around.</p>
              </div>

            </div>

          </div>
          {/* Accomodation Section End */}



          {/* Guidelines Section Start */}
          <div className={activeTab == 'guidelines' ? 'row col-lg-10 mx-auto col-12 px-0' : 'd-none'}>
            <p className='my-0 mt-4 sub-text'>GUIDELINES – KINDLY READ ALL THE GUILDEINES BEFORE REGISTRATION</p>
            <p className='my-0 mt-4 content-para'>
              <ul className='px-3'>
                <li>Age limit – 18 years and above</li>
                <li>Registrations should be done through this portal only.</li>
                <li>The registration process is explained fully once you click the “REGISTER NOW” button.</li>
                <li>Kindly fill the registration form and make the payment of Rs. 2000/- through the online payment portal provided.</li>
                <li>Once registration and payment are done, you will be added to a WhatsApp
                  group and a confirmation email will be sent with all the needed information.</li>
                <li>The WhatsApp group and your email will be the primary mode of communication.</li>
                <li>The travel, accommodation, food, and in-city logistics should be taken care by the participants.</li>
                <li>List of hotels in various tariffs (within 2 kms radius from the venue) with
                  contacts, details of local cab facilities and contact numbers will be shared.</li>
                <li>The location maps of rehearsal and concert venues will be provided.</li>
                <li>Soft copy of the music score and practice materials will be uploaded on the
                  website, which can be accessed after registration. If needed online sectional rehearsals will be organized and be intimated.</li>
              </ul>
            </p>
            <p className='my-0 mt-2 content-para'>
              <b>FOR ANY CLARIFICATION REGARDING REGISTRATION PLEASE CONTACT:</b>
              <p>LSM Organizing Secretary: <b>+919080093092</b> or email us at: <b>singmessiah2023@gmail.com</b></p>
            </p>

            <p className='my-0 mt-2 content-para'>Kindly note that your completed registration and successful payment will be acknowledged via
              email with confirmation of your requirements according to your registration form. Your registration
              will only be processed or confirmed if payment does accompany your form.</p>

            <p className='my-0 mt-4 sub-text'>Terms & Conditions:</p>
            <p className='my-0 mt-2 content-para'>The program is correct at the time of publishing. The organisers reserve the right to delete,
              modify or alter items from the program or to delete, modify or alter any aspect of the convention
              timetabling and delivery at their sole discretion and without notice. Neither the host
              organisation(s) nor the organizers will accept any liability for any loss or inconvenience caused to
              any party consequent to such changes.</p>
            <p className='my-0 mt-2 content-para'>FORCE MAJEURE. The organizers will not be liable for any failure or delay in performing an obligation due to any of the following causes, to the extent beyond its reasonable control: acts of
              God, accident, riots, war, terrorist act, epidemic, pandemic (including the COVID-19 pandemic),
              quarantine, civil commotion, breakdown of communication facilities, breakdown of web host,
              breakdown of internet service provider, natural catastrophes, governmental acts or omissions, changes in laws or regulations, national strikes, fire, explosion, or generalized lack of availability of raw materials or energy.</p>

            <p className='my-0 mt-2 content-para'>Safety of the belongings of each participant lies within them and the organisers will not be held
              responsible. </p>
            <p className='my-0 mt-2 content-para'>Nature is not in the control of the organisers. </p>
            <p className='my-0 mt-2 content-para'>Unforeseen circumstances such as natural disasters/political issues/bandhs/etc will not hold organisers responsible. </p>
          </div>
          {/* Guidelines Section End */}



          {/* FAQ Section Start */}
          <div className={activeTab == 'faq' ? 'row col-lg-10 mx-auto col-12 px-0 faq-section' : 'd-none'}>
            <div>
              <p className='faq-question'>WHAT IS THE LET’S SING MESSIAH CONCERT (LSM) ALL ABOUT?</p>
              <p className='faq-answer'>‘Let’s Sing Messiah’ is a two-day choir convention that aims to bring choristers, singers, and musicians all across the Indian sub-continent to come together on one platform to perform the oratorio <a href="https://en.wikipedia.org/wiki/Messiah_(Handel)">Messiah</a> by George Fredric Handel.</p>
            </div>
            <div>
              <p className='faq-question'>WHAT PURPOSE IS LSM TRYING TO ACHIEVE?</p>
              <p className='faq-answer'>The purpose is to bring together singers and choristers who would like to sing or perform classical repertoire with a big choir and a professional orchestra. Further, this would help musicians connect and network for meaningful work and collaborations in future. It will help further the scope of choral community in India
              </p>
            </div>
            <div>
              <p className='faq-question'>WHEN and WHERE IS IT HAPPENING?</p>
              <p className='faq-answer'>When: On January 27th and 28th, 2023.
                Where: Hindustan Arts and Science College, Nava India, Coimbatore, Tamil Nadu
              </p>
            </div>
            <div>
              <p className='faq-question'>CAN I BE A PART OF IT?</p>
              <p className='faq-answer'>Yes, you can. You need to be above 18 years of age.
                You have to register online by going to the website <a href="www.coimbatorechamberchorale.com">www.coimbatorechamberchorale.com</a>
              </p>
            </div>
            <div>
              <p className='faq-question'>IS PAYMENT INVOLVED?</p>
              <p className='faq-answer'>Yes, The payment is Rs. 2000/- INR for an individual.
                Includes: 1 workshop cum rehearsal, 2 full rehearsals, a concert, refreshments, welcome kit, resource materials and a gala dinner.
                Does Not Include: Boarding, Lodging, Food, Travel, Or In-city Logistics.
              </p>
            </div>
            <div>
              <p className='faq-question'>WHAT MODES OF PAYMENTS ARE AVAILABLE?</p>
              <p className='faq-answer'>Once you fill the online registration form, you will be asked to pay before submitting the form
                through our payment gateway. We support UPI, credit/debit cards and netbanking.
              </p>
            </div>
            <div>
              <p className='faq-question'>WHAT IF I AM CHARDGED TWICE FROM MY BANK ACCOUNT DURING REGISTRATION?</p>
              <p className='faq-answer'>During transaction, do not refersh or press back button. If your money is debited twice due to some glitch,
                do not panic. Take a screenshot of the transaction history and write to us at singmessiah2023@gmail.com. We have you covered. We will verify
                the transaction and initiate a refund if needed.
              </p>
            </div>
            <div>
              <p className='faq-question'>IN CASE OF INCORRECT/DUPLICATE TRANSACTIONS, HOW LONG WILL IT TAKE FOR THE MONEY TO BE REFUNDED?</p>
              <p className='faq-answer'>It will approximately take 10 business days.
              </p>
            </div>
            <div>
              <p className='faq-question'>WHAT IF MY REGISTRATION HAS FAILED BUT MONEY IS DEBITED FROM MY BANK ACCOUNT?</p>
              <p className='faq-answer'>Do not worry. Write to us at singmessiah2023@gmail.com attaching the transaction statement.
                You could give a call to the number specified in the contact section of our website. We will verify and help you with your registration.
              </p>
            </div>
            <div>
              <p className='faq-question'>WHAT DOES THE PROGRAM SCHEDULE LOOK LIKE?</p>
              <p className='faq-answer'>27th Jan 2023
                Morning (9AM- 12 PM) Singing Workshop Cum Rehearsal<br></br>
                Evening (4PM-7PM) Full Rehearsal<br></br>
                Evening (7.30 PM) Gala Dinner and Entertainment
              </p>
              <p className='faq-answer'>28th Jan 2023
                Morning – 9AM -12 PM - Final Rehearsal<br></br>
                Evening – 5PM Onwards – Let’s Sing Messiah Concert
              </p>
            </div>
            <div>
              <p className='faq-question'>SHOULD WE REGISTER AS INDIVIDUALS OR CAN WE REGISTER AS CHOIR GROUPS?
              </p>
              <p className='faq-answer'>Please make sure you register as an individual. There are no restrictions to how many can join from a particular choir. However, the registration seats fill up on first come first serve basis.
              </p>
            </div>
            <div>
              <p className='faq-question'>WILL THE ORGAINZING TEAM HELP ME FIND BOARD AND LODGING, FOOD, AND IN-CITY TRANSPORT?
              </p>
              <p className='faq-answer'>We are partnering with a Travel Agency. Please contact the agency for boarding, lodging and accomodation. The details are available in the 'accomodation' section of the Event Info
              </p>
            </div>
            <div>
              <p className='faq-question'>WILL THE ORGAINZING TEAM HELP ME WITH MY FLIGHT, TRAIN, OR BUS BOOKINGS AND PICK-UPS?
              </p>
              <p className='faq-answer'>We are partnering with a Travel Agency. Please contact the agency for boarding, lodging, travel and accomodation. The details are available in the 'accomodation' section of the Event Info
              </p>
            </div>
            <div>
              <p className='faq-question'>HOW DO I GET MY MUSIC SCORES?
              </p>
              <p className='faq-answer'>The music scores of each part – Soprano, Alto, Tenor, Bass will be uploaded in <a href="www.coimbatorechamberchorale.com">www.coimbatorechamberchorale.com</a> Once uploaded, it will be intimated to the participants through Whatsapp or email.
              </p>
            </div>
            <div>
              <p className='faq-question'>HOW DO I PRACTICE? DO WE HAVE ZOOM PRACTICES?
              </p>
              <p className='faq-answer'>Sectional practice schedules for all the four voice-parts will be scheduled and intimated to the registered participants through Whatsapp or Email.
              </p>
            </div>
            <div>
              <p className='faq-question'>WHO IS THE ORGAINSING SOCIETY?
              </p>
              <p className='faq-answer'>The Coimbatore Chamber Chorale Society is organizing society for the event.
              </p>
            </div>
            <p>For more information about the society, kindly visit the website: www.coimbatorechamberchorale.com<br></br>
              (or)<br></br>
              Write to: coimbatorechamberchorale@gmail.com
            </p>
          </div>
          {/* FAQ Section End */}
          <p className='my-0 mt-4 sub-text'>CONTACT DETAILS</p>
          <div>PHONE NUMBER : <b>+919080093092</b> | EMAIL : <b>singmessiah2023@gmail.com</b></div>
        </div>

      </div>
    </div >
  )
}

export default EventInfo;
