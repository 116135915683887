import React from 'react';
import './EventTickets.css';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Loader from '../Loader/Loader';
import ScrollToTop from '../../services/scrollToTop';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import countries from '../../assets/json/countryCodes.json';
import apiInstance from '../../services/httpService';
import paymentService from '../../services/paymentService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';

const ModalTransition = () => {
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
}

const EventTickets = () => {

    const [isLoading, setIsLoading] = React.useState(false)
    const [defaultCode, setDefaultCode] = React.useState("+91");
    const [seatClass, setSeatClass] = React.useState("");

    const [formData, setFormData] = React.useState({
        name: "",
        phone: "",
        email: "",
        dial_code: "+91",
        ticket_count: 0,
        ticket_class: seatClass
    });

    const dial_codeField = React.useRef(null);
    const phone_Field = React.useRef(null);

    const [modalOpen, setModalOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('success')
    const [message, setMessage] = React.useState('')

    const [isBookingCompleted, setIsBookingCompleted] = React.useState(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleChange = e => {
        let { name, value } = e.target;
        if (name == 'ticket_count') {
            if (value) {
                value = Number(value)
            } else {
                value = 0
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDailCodeChange = e => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setDefaultCode(e.target.value);
    }

    const initiatePayment = (ticket_id, total_amount) => {
        const otherData = {
            ticket_id: ticket_id,
            email: formData.email
        }
        paymentService.createRazorpayInstance(total_amount, "Let's Sing Messiah", 'Concert Ticket Purchase', `lsm_s_logo.png`, formData.email, formData.phone, paymentSuccessCallbackMethod, otherData);
    }

    const paymentSuccessCallbackMethod = (response, otherData) => {
        if (response.razorpay_payment_id) {
            setSeverity('success')
            setMessage('Payment Successful.')
            setOpen(true)
            otherData = {
                ...otherData,
                razorpay_id: response.razorpay_payment_id
            }
            setIsLoading(true);
            completePayment(otherData);
        } else {
            setSeverity('error')
            setMessage('Payment Failed. Please check your transaction.')
            setOpen(true)
        }
    }

    const completePayment = (data) => {
        apiInstance.binaryFileAxiosCall('api/ccc/lsm/ticket/confirm', { data: data }, 'POST').then((response) => {
            setIsLoading(false);
            setIsBookingCompleted(true);
            var downloadLink = document.createElement('a')
            downloadLink.target = '_blank'
            downloadLink.download = 'LSM_Concert_Ticket.pdf'
            var blob = new Blob([response.data], { type: 'application/pdf' })
            var URL = window.URL || window.webkitURL
            var downloadUrl = URL.createObjectURL(blob)
            // set object URL as the anchor's href
            downloadLink.href = downloadUrl
            // append the anchor to document body
            document.body.append(downloadLink)
            // fire a click event on the anchor
            downloadLink.click()
            // cleanup: remove element and revoke object URL
            document.body.removeChild(downloadLink)
            URL.revokeObjectURL(downloadUrl)
        })
    }

    const submitForm = () => {
        if (!formData.name) {
            console.log(formData)
            setSeverity('error')
            setMessage('Name field is Mandatory!! Please enter all the mandatory fields.')
            setOpen(true)
            return false;
        } else if (!formData.email) {
            console.log(formData)
            setSeverity('error')
            setMessage('Email field is Mandatory!! Please enter all the mandatory fields.')
            setOpen(true)
            return false;
        }
        else if (!formData.phone) {
            console.log(formData)
            setSeverity('error')
            setMessage('Phone Number field is Mandatory!! Please enter all the mandatory fields.')
            setOpen(true)
            return false;
        } else if (!formData.ticket_count) {
            console.log(formData)
            setSeverity('error')
            setMessage('Please specify the number of tickets you wish to book.')
            setOpen(true)
            return false;
        }
        else if (!formData.ticket_class) {
            console.log(formData)
            setSeverity('error')
            setMessage('Please select a seat class.')
            setOpen(true)
            return false;
        }
        apiInstance.axiosCall('api/ccc/lsm/ticket/book', { data: formData }, 'POST').then((response) => {
            if (response.data.status === 'success') {
                initiatePayment(response.data.data.ticket_id, response.data.data.total_amount);
            } else {
                setSeverity('error')
                setMessage(response.data.message.join(', '))
                setOpen(true)
            }
        })
    }

    const makeSelection = (selectedSeatClass) => {
        setSeatClass(selectedSeatClass);
        setFormData(prevState => ({
            ...prevState,
            ['ticket_class']: selectedSeatClass
        }));
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <div>
            <ScrollToTop />
            <Navbar />
            <div className='row col-lg-12 mx-0'>
                <div className='events-banner text-center col-lg-12'>
                    <h1 className='events-banner-title title-text mx-auto'>Ticket Booking</h1>
                    <div className='col-12 text-center my-2'><img className='lsm-event-logo mx-auto' src={require('../../assets/lsm_logo.png')} /></div>
                </div>
            </div>
            <div className='row col-lg-10 mx-auto mt-5'>
                <div className='img-banner'></div>
            </div>
            {
                !isBookingCompleted ?
                    <div className='row col-lg-10 mx-auto mt-5'>
                        <div className='col-lg-9 form-item text-center mx-auto'>
                            <h2 className='title'>Book your seat now</h2>
                        </div>
                        <div className='col-lg-9 form-item my-2 text-center mx-auto'>
                            <label className='email-disclaimer'>*Ticket will be sent to the email address that you provide here. So please make sure it is correct and valid.</label>
                        </div>
                        <div className='col-lg-6 my-2 form-item'>
                            <TextField className='col-lg-12 mat-text-field' autoComplete='name-val' id="outlined-basic" label="Name *" name='name' variant="outlined" onKeyUp={handleChange} inputProps={{ autoComplete: 'off' }} />
                        </div>
                        <div className='col-lg-6 my-2 form-item'>
                            <TextField className='col-lg-12 mat-text-field' autoComplete='email-val' id="outlined-basic" label="Email *" name='email' variant="outlined" onKeyUp={handleChange} inputProps={{ autoComplete: 'off' }} />
                        </div>

                        <div className='col-lg-2 col-5 my-2 form-item'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Code *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="city"
                                    inputRef={dial_codeField}
                                    value={defaultCode ? defaultCode : null}
                                    label="Code *"
                                    name="dial_code"
                                    onChange={handleDailCodeChange}
                                >
                                    {
                                        countries.countries.map((item, i) => <MenuItem value={item.code}>{item.name + ' (' + item.code + ')'}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-lg-4 col-7 my-2 form-item'>
                            <TextField className='col-lg-12' autoComplete='phoneno' inputRef={phone_Field} id="outlined-basic" label="Phone *" variant="outlined" name='phone' onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} type="number" onKeyUp={handleChange} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className='col-lg-6 my-2 form-item'>
                            <TextField className='col-lg-12 mat-text-field' autoComplete='name-val' id="outlined-basic" label="Ticket Count *" name='ticket_count' variant="outlined" type="number" onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                            }} onKeyUp={handleChange} inputProps={{ autoComplete: 'off' }} onWheel={(e) => e.target.blur()} />
                        </div>
                        <div className='d-flex labels mt-2'>
                            <label className='seat-class-label'>Seat class*</label>
                            <a className='' href='javascript:;' onClick={handleModalOpen}>View Seat Map</a>
                        </div>
                        <div className='seat-classes col-lg-12'>
                            <div onClick={() => makeSelection('A')} className='a-class class my-2 p-3'>
                                <img src={require("../../assets/comfort_seat.png")} />
                                <div>
                                    <h4>Fugue</h4>
                                    <h5 className='price'><sup className='rupees-sign'>₹</sup> 1000 <label>per person</label></h5>
                                </div>
                                <input onChange={() => makeSelection('A')} type="radio" name="ticket_class" value="A" checked={seatClass === "A"} />
                            </div>
                            <div onClick={() => makeSelection('B')} className='b-class class my-2 p-3'>
                                <img src={require("../../assets/comfort_seat.png")} />
                                <div>
                                    <h4>Canon</h4>
                                    <h5 className='price'><sup className='rupees-sign'>₹</sup> 750 <label>per person</label></h5>
                                </div>
                                <input onChange={() => makeSelection('B')} type="radio" name="ticket_class" value="B" checked={seatClass === "B"} />
                            </div>
                            <div onClick={() => makeSelection('C')} className='c-class class my-2 p-3'>
                                <img src={require("../../assets/economy_seat.png")} />
                                <div>
                                    <h4>Invention</h4>
                                    <h5 className='price'><sup className='rupees-sign'>₹</sup> 500 <label>per person</label></h5>
                                </div>
                                <input onChange={() => makeSelection('C')} type="radio" name="ticket_class" value="C" checked={seatClass === "C"} />
                            </div>
                        </div>
                        <div className='col-lg-12 px-2'>
                            <div className='proceed-btn my-4 m-auto col-lg-3 justify-content-center' onClick={submitForm}>Proceed to Pay</div>
                        </div>
                        <div className='px-2'>
                            <div className='important-notes mt-2 my-4 px-2'>
                                <h4 className='my-3 mt-0'>Important Points to Note</h4>
                                <p className='text-justify'>- If you face any issues in between the transaction or if your amount is deducted after any error, Please do not panic. Contact us @ +919080093092</p>
                                <p className='text-justify my-0'>- The seats can be occupied on First Come First Serve (FCFS) basis.</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='confirmation row col-lg-10 mx-auto my-5'>
                        <h4>Your ticket is downloaded. We have also emailed this ticket to you.</h4>
                        <p>Looking forward to seeing you. Make sure you show the softcopy of this ticket at the entrance of the concert hall.</p>

                        <div className='confirmation-footer'>
                            <h4>Thanks,</h4>
                            <h4>Team LSM</h4>
                        </div>
                    </div>
            }
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Dialog
                open={modalOpen}
                TransitionComponent={ModalTransition.Transition}
                keepMounted
                onClose={handleModalClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Seat Map"}</DialogTitle>
                <div className='seat-map-wrapper'>
                    <img className='seat-map' src={require("../../assets/seat_map.png")} />
                </div>
            </Dialog>
            <Footer />
        </div>
    )
}

export default EventTickets;
