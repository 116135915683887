const paymentService = {
    // Creates a payment instance
    // * amount to be paid
    // * eventName denotes the name of the event for which the payment is inititated
    // * eventDescription is a description for user's understanding of the event
    // * eventIcon is the name of the icon file for the event (should be prsent in the assets)
    // * email and phone are the billing Info
    // * callBackMethod is the method which should be triggered after successful payment
    // * otherReturnData is an object that will be returned as such
    async createRazorpayInstance(amount, eventName, eventDescription, eventThumbnail, email, phone, callBackMethod, otherReturnData) {
        const res = await _loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert("Your offline. Failed to load. Please check the internet connection.")
            return
        }

        const options = {
            //key: 'rzp_test_FUIu93rERk3IFO',
            key: 'rzp_live_Mw58S1lK7lN6RR',
            currency: 'INR',
            amount: amount * 100,
            name: eventName,
            description: eventDescription,
            image: require('../assets/' + eventThumbnail),
            handler: (response) => {
                callBackMethod(response, otherReturnData);
            },
            prefill: {
                email: email,
                contact: phone
            },
            theme: {
                color: "#222222"
            }
        };
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }
}

// Creates a new promise that loads the razor pay library 
const _loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src

        script.onload = () => {
            resolve(true)
        }

        script.onerror = () => {
            resolve(false)
        }

        document.body.appendChild(script)
    })
}

export default paymentService;