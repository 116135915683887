import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import Register from './components/Register/Register';
import SetPassword from './components/SetPassword/SetPassword';
import MemberArea from './components/MemberArea/MemberArea';
import AboutExtended from './components/AboutExtended/AboutExtended';
import EventsExtended from './components/EventsExtended/EventsExtended';
import MediaExtended from './components/MediaExtended/MediaExtended';
import BlogsExtended from './components/BlogsExtended/BlogsExtended';
import Contact from './components/Contact/Contact';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import CancellationRefundPolicy from './components/CancellationRefundPolicy/CancellationRefundPolicy';
import EventTickets from './components/EventTickets/EventTickets';
import Login from './components/Login/Login';
import LsmInfo from './components/lsmInfo/lsmInfo';

// Boostrap imports
import 'bootstrap/dist/css/bootstrap.min.css';

// import router
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import ReactGA from "react-ga4";
import RegistrationsClosed from './components/registrationsClosed/registrationsClosed';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#FC5252',
      dark: '#000'
    },
    secondary: {
      main: '#f44336',
    },
  },
  typography: {
    useNextVariants: true
  }
});

ReactGA.initialize("G-CQ6X8DNG4Q");
ReactGA.send("pageview");

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/register" element={<Register />} />
          <Route path="/setPassword" element={<SetPassword />} />
          <Route path="/memberArea/*" element={<MemberArea />} />
          <Route path="/about" element={<AboutExtended />} />
          <Route path="/events" element={<EventsExtended />} />
          <Route path="/media" element={<MediaExtended />} />
          <Route path="/blogs" element={<BlogsExtended />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
          <Route path="/registrationsClosed" element={<RegistrationsClosed />} />
          <Route path="/eventTickets" element={<EventTickets />} />
          <Route path="/lsmInfo" element={<LsmInfo />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
