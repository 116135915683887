import React from 'react';
import './Footer.css';
import { Navbar, Nav, Container, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }
  return (
    <div className="Footer">
      <div className='social-media-icons d-flex align-items-center justify-content-center my-3'>
        <img className='mx-2' onClick={() => window.open('https://www.facebook.com/CoimbatoreChamberChorale', '_blank')} src={require("../../assets/fb.png")} />
        <img className='mx-2' onClick={() => window.open('https://www.instagram.com/coimbatorechamberchorale/', '_blank')} src={require("../../assets/instagram.png")} />
      </div>
      <div className='policies d-flex justify-content-center mt-4'>
        <p className='m-0 terms-and-conditions' onClick={() => navigateTo('/terms-and-conditions')}>Terms and Conditions</p>
        <p className='pipe m-0 mx-2'>|</p>
        <p className='m-0 cancellation-refund-policy' onClick={() => navigateTo('/cancellation-refund-policy')}>Refund Policy</p>
        <p className='pipe m-0 mx-2'>|</p>
        <p className='m-0 privacy-policy' onClick={() => navigateTo('/privacy-policy')}>Privacy Policy</p>
      </div>
      <div className='policies d-flex justify-content-center mt-4'>
        <p className='m-0 mt-1'>Copyright © 2022 Coimbatore Chamber Chorale</p>
        <p className='pipe m-0 mt-1 mx-2'>|</p>
        <p className='m-0 mt-1'>All Rights Reserved</p>
      </div>

    </div>
  )
}

export default Footer;
