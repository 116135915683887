import React from 'react';
import './Certificate.css';
import Pdf from "react-to-pdf";
import CertificateImage from '../../assets/certificate.png'
import TextField from '@mui/material/TextField';
import apiInstance from '../../services/httpService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Certificate = ({ name, isFeedbackFilled }) => {
  const ref = React.createRef();
  const [isCertificateShown, setIsCertificateShown] = React.useState('none')
  const options = {
    orientation: 'portrait',
  };
  const [formData, setFormData] = React.useState({
    highlights: "",
    improvements: "",
  });
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')

  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState(isFeedbackFilled ?? false)

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const downloadCertificate = (pdfInstance) => {
    setIsCertificateShown('block')
    setTimeout(() => {
      pdfInstance()
      setIsCertificateShown('none')
    }, 1);
    setTimeout(() => {
      setSeverity('success')
      setMessage('Your certificate is downloaded.')
      setOpen(true)
    }, 2000)
    setTimeout(() => {
      window.location.reload(true)
    }, 5000)
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const submitFeedback = () => {
    if (!formData.highlights) {
      setSeverity('error')
      setMessage('"What went well?" field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      return false;
    } else if (!formData.improvements) {
      setSeverity('error')
      setMessage("'What didn't go well?' field is Mandatory!! Please enter all the mandatory fields.")
      setOpen(true)
      return false;
    }
    const data = {
      data: {
        highlights: formData.highlights,
        improvements: formData.improvements
      },
      token: localStorage.getItem('token')
    }
    getCheckInStatus(data)
  }

  const getCheckInStatus = (data) => {
    apiInstance.axiosCall('api/ccc/lsm/feedback', data, 'POST').then((response) => {
      if (response.data.status === 'success') {
        setIsFeedbackSubmitted(response.data.data.feedback)
        setFormData({ highlights: "", improvements: "" })
        setSeverity('success')
        setMessage('Your feedback is saved. Thank you!')
        setOpen(true)
      } else {
        setSeverity('error')
        setMessage(response.data.message.join(', '))
        setOpen(true)
      }
    })
  }

  return (
    <div>
      {
        !isFeedbackSubmitted ?
          <div className='col-lg-10 row mx-auto'>
            <h2 className='form-title'>Fill the feedback form and get your certificate</h2>
            <div className='col-lg-6 my-2 form-item'>
              <TextField multiline className='col-lg-12 mat-text-field' rows={3} autoComplete='name-val' id="outlined-basic" label="What went well? *" name='highlights' variant="outlined" onKeyUp={handleChange} inputProps={{ autoComplete: 'off' }} />
            </div>
            <div className='col-lg-6 my-2 form-item'>
              <TextField multiline className='col-lg-12 mat-text-field' rows={3} autoComplete='email-val' iwd="outlined-basic" label="What didn't go well? *" name='improvements' variant="outlined" onKeyUp={handleChange} inputProps={{ autoComplete: 'off' }} />
            </div>
            <div className='col-lg-12 px-2'>
              <div className='proceed-btn my-4 m-auto col-lg-3 justify-content-center' onClick={submitFeedback} >Submit Feedback</div>
            </div>
          </div>
          :
          <div>
            <div className='col-lg-10 row mx-auto'>
              <div className='col-lg-12 certificate-preview-wrapper row mx-0'>
                <div className='col-lg-6 certificate-btn-section'>
                  <Pdf targetRef={ref} filename="LSM-Certificate.pdf" options={options} x={.5} y={.5} scale={0.8}>
                    {({ toPdf }) => (
                      <div className='proceed-btn my-3' onClick={() => downloadCertificate(toPdf)}>Download my Certificate</div>
                    )}
                  </Pdf>
                </div>
                <div className='col-lg-6 certificate-btn-section'>
                  <img src={CertificateImage} className="certificate-img" />
                </div>
              </div>
            </div>
            <div className={isCertificateShown == 'block' ? 'certificate-show' : 'certificate-hide'} style={{ width: 988, height: 1399, position: 'relative' }} ref={ref}>
              <img src={CertificateImage} className="certificate-img" />
              <h3 className='certificate-name'>{name}</h3>
            </div>
          </div>
      }
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
};

export default Certificate;
