import React from 'react';
import './Login.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../../services/scrollToTop';
import Footer from '../Footer/Footer';

import TextField from '@mui/material/TextField';
import apiInstance from '../../services/httpService';
import { useSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [isPasswordResetMailSuccess, setIsPasswordResetMailSuccess] = React.useState(false)
  const [passwordResetMessage, setPasswordResetMessage] = React.useState('')
  const [isLogin, setIsLogin] = React.useState(true)

  const [formData, setFormData] = React.useState({
    email: '',
    password: ''
  })

  const [resetFormData, setResetFormData] = React.useState({
    email: ''
  })

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleResetFormChange = e => {
    const { name, value } = e.target;
    setResetFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }

  const submitForm = e => {
    if (!formData.email) {
      setSeverity('error')
      setMessage('Enter Email.')
      setOpen(true)
      return false;
    } else if (!formData.password) {
      setSeverity('error')
      setMessage('Enter Password.')
      setOpen(true)
      return false;
    }
    apiInstance.axiosCall('/api/ccc/user/login', formData, 'POST').then((response) => {
      console.log(response)
      if (response.data.status == 'success') {
        localStorage.setItem('token', response.data.token)
        setSeverity('success')
        setMessage(response.data.message)
        setOpen(true)
        setTimeout(navigateTo('/memberArea'), 3000);
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
        return false;
      }
    })
  };

  const resetPassword = () => {
    if (!resetFormData.email) {
      setSeverity('error')
      setMessage('Enter Email.')
      setOpen(true)
      return false;
    }

    if (!isValidEmail(resetFormData.email)) {
      setSeverity('error')
      setMessage('Please enter a valid email.')
      setOpen(true)
      return false;
    }

    apiInstance.axiosCall('/api/ccc/user/req_pwd_reset', resetFormData, 'POST').then((response) => {
      console.log(response)
      if (response.data.status == 'success') {
        setPasswordResetMessage(response.data.message)
        setIsPasswordResetMailSuccess(true)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  // Email Validator Function
  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  return (
    <div className="Login">
      <ScrollToTop />
      <Navbar />
      <div className='row col-lg-12 mx-0'>
        <div className='login-banner col-lg-12'>
          <h1 className='login-banner-title mx-auto'>Login</h1>
        </div>
      </div>

      {
        !isPasswordResetMailSuccess ?
          <div className='row col-lg-6 my-4 mx-auto justify-content-center px-2'>
            <div className='login-wrapper row col-lg-12 p-0'>
              <div className='p-0'><img src={require("../../assets/messiah_3.jpg")} /></div>

              {
                isLogin ?
                  <div className='form-section row py-5 m-auto'>
                    <h3 className='set-up-label'>Login</h3>
                    <div className='col-lg-12 my-2 form-item'>
                      <TextField className='col-lg-12' autoComplete='off' id="outlined-basic" label="Email" variant="outlined" name='email' onKeyUp={handleChange} />
                    </div>
                    <div className='col-lg-12 my-2 form-item'>
                      <TextField className='col-lg-12' autoComplete='off' id="outlined-basic" label="Password" variant="outlined" type='password' name='password' onKeyUp={handleChange} />
                    </div>
                    <div className='col-lg-12 forgot-password-label'><label onClick={() => { setIsLogin(false) }}>Forgot Password?</label></div>
                    <div className='col-lg-12 my-2 form-item'>
                      <div className='know-more-btn mt-4 m-auto col-lg-3 col-12 justify-content-center' onClick={submitForm}>Submit</div>
                    </div>
                  </div>
                  :
                  <div className='form-section row py-5 m-auto'>
                    <h3 className='set-up-label'>Forgot Password</h3>
                    <div className='col-lg-12 my-2 form-item' key={2}>
                      <TextField className='col-lg-12' autoComplete='off' id="outlined-basic" label="Email" variant="outlined" name='email' onKeyUp={handleResetFormChange} />
                    </div>
                    <div className='col-lg-12 forgot-password-label'><label onClick={() => { setIsLogin(true) }}>Back to Login</label></div>
                    <div className='col-lg-12 my-2 form-item'>
                      <div className='know-more-btn mt-4 m-auto col-lg-3 col-12 justify-content-center' onClick={resetPassword}>Reset Password</div>
                    </div>
                  </div>
              }

            </div>
          </div>
          :
          <div>
            <div className='registered-message-section col-lg-10 col-md-10 col-11 mx-auto my-4'>
              <div className='my-3 mx-auto text-center'><img src={require("../../assets/tick.png")} /></div>
              <div>{passwordResetMessage}</div>
            </div>
          </div>
      }


      {/* Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Footer />
    </div >
  )
}

export default Login;
