import * as CryptoJS from 'crypto-js';

const BASE_URL = "https://coimbatorechamberchorale.com/";

const apiInstance = {

    encrypt(data) {
        const key = 'Secret Key to be Decided';
        const iv = 'IV to be Decided';

        try {
            return CryptoJS.enc.Base64.stringify(
                // AES Encryption
                CryptoJS.AES.encrypt(
                    JSON.stringify(data),
                    CryptoJS.enc.Utf8.parse(key),
                    {
                        iv: CryptoJS.enc.Utf8.parse(iv),
                        mode: CryptoJS.mode.CBC,
                        padding: CryptoJS.pad.Pkcs7,
                    }
                ).ciphertext
            );
        } catch {
            return null;
        }
    },

    request(data) {
        console.log(this.encode(data));
        return { data: this.encode(data) };
    },

    decrypt(data) {
        const key = 'Secret Key to be Decided';
        const iv = 'IV to be Decided';

        try {
            return JSON.parse(
                CryptoJS.AES.decrypt(data.response, CryptoJS.enc.Utf8.parse(key), {
                    iv: CryptoJS.enc.Utf8.parse(iv),
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                }).toString(CryptoJS.enc.Utf8)
            );
        } catch {
            return null;
        }
    },

    fetchCall(apiUrl, params, method, qstring) {
        const requestFetch = {
            method: method,
            headers: {
                'Content-Type': 'application/pdf'
            },
        };

        if (method === 'POST') {
            requestFetch['body'] = JSON.stringify(params);
        } else if (qstring) {
            apiUrl = this.convertUrl(apiUrl, params);
        }

        return fetch(apiUrl, requestFetch)
            .then((response) => response.json())
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    axiosCall(apiUrl, data, method) {
        const axios = require('axios');
        const requestAxios = {
            method: method,
            url: apiUrl,
            headers: { 'Content-Type': 'application/json' }
        };

        if (method === 'POST') {
            // When Encryption needed
            // requestAxios['data'] = this.request(params);
            requestAxios['data'] = data;
        }

        return axios(requestAxios)
            .then((response) => response)
            .catch(error => {
                console.error('There was an error! Please check your internet connection.', error);
            })
    },

    binaryFileAxiosCall(apiUrl, data, method,) {
        const axios = require('axios');
        return axios({
            method: method,
            url: apiUrl,
            data: data,
            responseType: 'arraybuffer'
        })
            .then((response) => response)
            .catch(error => {
                console.error('There was an error! Please check your internet connection.', error);
            })
    },

    convertUrl(apiUrl, params) {
        apiUrl += '?';
        let query = Object.keys(params).map(k =>
            encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
        ).join('&');
        apiUrl += query;

        return apiUrl;
    }
}

export default apiInstance;
