import React from 'react';
import './PartVacancies.css';

const PartVacancies = ({ vacancies }) => {
  return (
    <div className="PartVacancies">
      <h3 className='set-up-label'>Seats availability</h3>
      <div className='vacancy-status d-flex'>
        <div className='mx-2 vacancy-status-item d-flex align-items-center'>
          <div className={`status mx-1 status-${vacancies.soprano}`}></div>Soprano
        </div>
        <div className='mx-2 vacancy-status-item d-flex align-items-center'>
          <div className={`status mx-1 status-${vacancies.alto}`}></div>Alto
        </div>
        <div className='mx-2 vacancy-status-item d-flex align-items-center'>
          <div className={`status mx-1 status-${vacancies.tenor}`}></div>Tenor
        </div>
        <div className='mx-2 vacancy-status-item d-flex align-items-center'>
          <div className={`status mx-1 status-${vacancies.bass}`}></div>Bass
        </div>
      </div>
    </div>
  )
};


export default PartVacancies;
