import React from 'react';
import Navbar from '../Navbar/Navbar';
import './registrationsClosed.css';

const RegistrationsClosed = () => {
  return (
    <div className="RegistrationsClosed">
      <Navbar />
      <div className='text-center'>
        <img className='logo_s' src={require('../../assets/lsm_s_logo.png')} />
        <h4 className='mt-3'>Sorry, you're a bit late.</h4>
        <p>The registrations are closed as of now. However if you wish to participate in the event please use the below contact details.</p>
        <div className='col-lg-12 row contact-wrapper'>
          <div className='col-lg-3'>
            <div className='features-card m-2 px-2'>+919080093092</div>
          </div>
          <div className='col-lg-3'>
            <div className='features-card m-2 px-2'>singmessiah2023@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationsClosed;
