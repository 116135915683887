import React, { useState, useEffect } from 'react';
import apiInstance from '../../services/httpService';
import './MemberArea.css';
import Payment from '../Payment/Payment';
import Resources from '../Resources/Resources';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../../services/scrollToTop';
import { Switch } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Footer from '../Footer/Footer';
import RegistrationCommittee from '../registrationCommittee/registrationCommittee';
import Attendees from '../Attendees/Attendees';
import Certificate from '../Certificate/Certificate';

const MemberArea = () => {
  const [activeTab, setActiveTab] = React.useState('dashboard')
  const [enrolledEvents, setEnrolledEvents] = React.useState([])
  const [userPermissions, setUserPermissions] = React.useState({ manage_registration: false })
  const data = { token: localStorage.getItem('token') }
  const [username, setUsername] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [isCheckedIn, setIsCheckedIn] = React.useState(false)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  }

  const getDashboardInfo = () => {
    apiInstance.axiosCall('api/ccc/user/dashboard', data, 'POST').then((response) => {
      console.log(response.data.data.events)
      if (response.data.status == 'success') {
        setUsername(response.data.data.name)
        setEnrolledEvents(response.data.data.events)
      }
    })
  }

  const getCheckInStatus = () => {
    apiInstance.axiosCall('api/ccc/lsm/feedback', { token: localStorage.getItem('token') }, 'POST').then((response) => {
      if (response.data.status === 'success') {
        setIsCheckedIn(response.data.data.checked_in)
        setIsFeedbackSubmitted(response.data.data.feedback)
        setFullName(response.data.data.name)
      } else {
        setSeverity('error')
        setMessage(response.data.message.join(', '))
        setOpen(true)
      }
    })
  }

  useEffect(() => {
    var token = localStorage.getItem('token')
    if (!token) {
      navigateTo('/')
    }
    getDashboardInfo()
    getCheckInStatus()
    apiInstance.axiosCall('api/ccc/user/permissions', data, 'POST').then((response) => {
      console.log(response)
      if (response.data.status == 'success') {
        setUserPermissions(response.data.data.permissions)
      } else {
        localStorage.setItem('token', '')
        navigateTo('/')
      }
    })
  }, []);


  return (
    <div className="MemberArea">
      <ScrollToTop />
      <Navbar />
      <div className='row col-lg-12 mx-0'>
        <div className='events-banner col-lg-12'>
          <h1 className='events-banner-title welcome-name text-center mx-auto'>Welcome {username}</h1>
        </div>
      </div>

      <div className='row col-lg-12 my-4'>
        <div className='tabs my-4 d-flex align-items-center justify-content-center'>
          <div className={activeTab == 'dashboard' ? 'tab-item active' : 'tab-item'} onClick={() => setActiveTab('dashboard')}>Dashboard</div>
          {
            userPermissions.manage_registration ? <div className={activeTab == 'man-reg' ? 'tab-item active' : 'tab-item'} onClick={() => setActiveTab('man-reg')}>Registration</div> : ''
          }
          {/* Uncomment the below when attendees list is needed for the event */}
          {
            /* {
              userPermissions.manage_registration ? <div className={activeTab == 'attendees' ? 'tab-item active' : 'tab-item'} onClick={() => setActiveTab('attendees')}>Attendees</div> : ''
            } */
          }
          {/* <div className={activeTab == 'resources' ? 'tab-item active' : 'tab-item'} onClick={() => setActiveTab('resources')}>Resources</div> */}
          {
            isCheckedIn ?
              <div className={activeTab == 'certificate' ? 'tab-item active' : 'tab-item'} onClick={() => setActiveTab('certificate')}>Certificate</div>
              :
              ''
          }
        </div>

        {/* Mount Component based on the accessible tabs for a user */}
        {(() => {
          switch (activeTab) {
            case 'dashboard':
              return <Payment />
            case 'man-reg':
              return <RegistrationCommittee />
            case 'attendees':
              return <Attendees />
            case 'resources':
              return <Resources eventsData={enrolledEvents} />
            case 'certificate':
              return <Certificate name={fullName} isFeedbackFilled={isFeedbackSubmitted} />
            default:
              return null
          }
        })()}
        {/* End Switch component */}

      </div>
      <Footer />
    </div>
  )
}

export default MemberArea;
