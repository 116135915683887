import React from 'react';
import './Home.css';
import Navbar from '../Navbar/Navbar'
import Logo from '../../assets/logo.png'
import PlayIcon from '../../assets/play-icon.png'

const Home = () => (
  <div className="Home">
    <div className='mx-5'>
      <h5 className='banner-text'>Connecting, Engaging and Strengthening communities through</h5>
      <h5 className='banner-text'>the unifying power of Choral Music</h5>
      <div className='d-flex align-items-center justify-content-center my-3 sub-text-wrapper'>
        <img className='play-icon' src={PlayIcon} />
        <p className='banner-sub-text my-0 mx-3' onClick={() => window.open("https://www.youtube.com/channel/UCGoEoxcGCvzQL7F4amv4_0w", "_blank")}>Watch Perfomance</p>
      </div>
    </div>
    <img className='banner-logo' src={Logo}></img>
  </div>
);

export default Home;

