import React, { useEffect } from 'react';
import './Navbar.css';
import Logo from '../../assets/logo.png';
import { Navbar, Nav, Container, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavbarComponent = () => {
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }

  const [token, setToken] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setToken(localStorage.getItem('token'))
  }, []);

  const logout = () => {
    localStorage.setItem('token', '')
    localStorage.setItem('username', '')
    navigateTo('/')
  }


  return (
    <div className="Navbar">
      {/* Responsive Nav Bar Section */}
      <Navbar className="navbar p-0" expand="lg">
        <Container>
          <div className="brand-wrapper d-flex justify-content-between">
            <Navbar.Brand onClick={() => navigateTo('/')} className="brand-name brand-name-left-wrapper"><img src={require("../../assets/logo.png")} className='brand-name-mob' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto m-auto align-items-center text-center">
              <Nav.Link className='mx-2' onClick={() => navigateTo('/about')}>About</Nav.Link>
              <Nav.Link onClick={() => navigateTo('/events')} className='mx-2'>Events</Nav.Link>
              <Nav.Link onClick={() => navigateTo('/media')} className='mx-2'>Media</Nav.Link>
              <Nav.Link onClick={() => navigateTo('/blogs')} className='mx-2'>Blogs</Nav.Link>
              <div className="d-flex justify-content-between">
                <Navbar.Brand onClick={() => navigateTo('/')} className='brand-name mx-2 my-0 brand-name-center-wrapper'>CCC</Navbar.Brand>
              </div>
              <Nav.Link onClick={() => navigateTo('/lsmInfo')} className='mx-2'>LSM</Nav.Link>
              <Nav.Link onClick={() => navigateTo('/')} className='mx-2'>Museed</Nav.Link>
              {
                token ?
                  <Nav.Link onClick={() => navigateTo('/memberArea')} className='mx-2'>My Area</Nav.Link>
                  :
                  <Nav.Link onClick={() => navigateTo('/login')} className='mx-2'>Login</Nav.Link>
              }
              <Nav.Link onClick={() => navigateTo('/contact')} className='mx-2'>Contact</Nav.Link>
              {
                token ?
                  <Nav.Link onClick={() => handleClickOpen()} className='logout-nav-item mx-2'>Logout</Nav.Link>
                  :
                  ''
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
        {
          token ?
            <img className='logout-icon' onClick={handleClickOpen} src={require('../../assets/logout.png')} />
            :
            ''
        }
      </Navbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to logout ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>Yes</Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default NavbarComponent;
