import React from 'react';
import './Blogs.css';

const Blogs = () => (
  <div className="Blogs my-3">
    <div className='col-lg-10 m-auto blogs-wrapper'>
      <div className='my-1 d-flex'>
        <div className='m-auto text-center'>
          <h4>Blogs</h4>
          <p className='blogs-sub-text'>Some of our Informative Blogs</p>
        </div>
      </div>

      <div className='row home-blogs-item-row'>

        <div className='col-lg-4 my-3 px-4 home-blogs-item-wrapper'>
          <div className='home-blogs-item'>
            <img className='blogs-img' src={require("../../assets/choir2.jpg")} />
            <div className='row blogs-info p-3'>
              <h5 className='home-blogs-title'>Top ten ideas to bring a soft unified sound in Choral singing</h5>
              <p className='home-blogs-desc my-2'>Content upcoming</p>
              <div className='d-flex justify-content-between my-3'>
                <p className='home-blogs-date m-0'>02 May 2022</p>
                <p className='home-blogs-author m-0'>Faith Ragland</p>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-4 my-3 px-4 home-blogs-item-wrapper'>
          <div className='home-blogs-item'>
            <img className='blogs-img' src={require("../../assets/Choir.jpg")} />
            <div className='row blogs-info p-3'>
              <h5 className='home-blogs-title'>Are you listening to other parts while you sing in a choir?</h5>
              <p className='home-blogs-desc my-2'>Content upcoming</p>
              <div className='d-flex justify-content-between my-3'>
                <p className='home-blogs-date m-0'>02 May 2022</p>
                <p className='home-blogs-author m-0'>Faith Ragland</p>
              </div>
            </div>
          </div>
        </div>

        <div className='col-lg-4 my-3 px-4 home-blogs-item-wrapper'>
          <div className='home-blogs-item'>
            <img className='blogs-img' src={require("../../assets/conductor.jpg")} />
            <div className='row blogs-info p-3'>
              <h5 className='home-blogs-title'>How to watch for the conductor's gesture ?</h5>
              <p className='home-blogs-desc my-2'>Content upcoming</p>
              <div className='d-flex justify-content-between my-3'>
                <p className='home-blogs-date m-0'>02 May 2022</p>
                <p className='home-blogs-author m-0'>Faith Ragland</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* Load More Button */}
      {/* <div className='load-more my-2 mx-auto'>Load More</div> */}

    </div>
  </div>
);

export default Blogs;
