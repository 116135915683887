import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import apiInstance from '../../services/httpService';
import MuiAlert from '@mui/material/Alert';
import './ResourceArea.css';

const ResourceArea = ({ changeActiveDir }) => {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [resourcesData, setResourcesData] = React.useState({})

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    let data = {
      data: {
        song_id: localStorage.getItem('resourceSongId')
      },
      token: localStorage.getItem('token')
    }
    apiInstance.axiosCall('api/ccc/user/song_details', data, 'POST').then((response) => {
      if (response.data.status == 'success') {
        setResourcesData(response.data.data)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    });
  }, []);

  const openLink = (url) => {
    window.open(url, '_blank')
  }
  return (
    <div className="ResourceArea">
      <Breadcrumbs separator=">" aria-label="breadcrumb" className='mx-2 my-3'>
        <Link underline="hover" key="1" color="inherit" onClick={() => changeActiveDir('events')}>
          Events
        </Link>
        <Link underline="hover" key="2" color="inherit" onClick={() => changeActiveDir('songs')}>
          Songs
        </Link>
        <Link underline="hover" key="3" color="inherit">
          Song Item
        </Link>
      </Breadcrumbs>
      <div className='row col-lg-12 mx-auto my-2'>
        <h4 className='events-header-label'>Rehearsal Tracks</h4>
        {
          resourcesData.song_list?.map((video) =>
            <div className='col-lg-4 pr-2 my-2 wrap'>
              <div className='video-detail col-lg-12'>
                <iframe
                  width="100%"
                  height="200"
                  src={video.uri}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <label className='video-display-name p-2'>{video.display_name}</label>
              </div>
            </div>
          )
        }
      </div>
      <div className='row col-lg-12 mx-auto my-2'>
        {
          resourcesData.score_list?.length > 0 ?
            <h4 className='events-header-label'>Scores</h4> :
            ''
        }
        {
          resourcesData.score_list?.map((resource) =>
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-6 col-12'>
              <div className='resources-event-item'>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon p-2' src={require('../../assets/pdf.png')} />
                  <div>
                    <label className='resources-item-label mx-3'>{resource.display_name}</label>
                    <p className='view-label my-0 mx-3' onClick={() => openLink(resource.uri)}>View/Download</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className='row col-lg-12 mx-auto my-2'>
        {
          resourcesData.general_resources?.length > 0 ?
            <h4 className='events-header-label'>General Resources</h4>
            :
            ''
        }
        {
          resourcesData.general_resources?.map((resource) =>
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-6 col-12'>
              <div className='resources-event-item'>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon' src={require('../../assets/download.png')} />
                  <div>
                    <label className='resources-item-label mx-3'>{resource.display_name}</label>
                    <p className='view-label my-0 mx-3' onClick={() => openLink(resource.uri)}>View/Download</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default ResourceArea;
