import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import './Loader.css';

const Loader = () => (
  <div className="Loader">
    <img src={require("../../assets/olive-logo.png")} alt='' />
    <LinearProgress className='linear-progress-item' />
  </div>
);

export default Loader;
