import React from 'react';
import './Quotes.css';

const Quotes = () => (
  <div className="Quotes">
    <img className='quotes-icon' src={require("../../assets/quotes-icon.png")} />
    <p>I should be sorry if I only entertained them. I wish to make them better.</p>
    <hr className='mt-5'></hr>
    <p className='author-label my-2'>George Frideric Handel</p>
  </div>
);

export default Quotes;
