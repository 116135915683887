import React from 'react';
import ScrollToTop from '../../services/scrollToTop';
import Footer from '../Footer/Footer';
import './CancellationRefundPolicy.css';
import Navbar from '../Navbar/Navbar'


const CancellationRefundPolicy = () => (
  <div className="CancellationRefundPolicy">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='privacy-banner col-lg-12'>
        <h1 className='privacy-banner-title text-center mx-auto'>Refund / Cancellation Policy</h1>
      </div>
    </div>
    <div className='row col-lg-12 mx-0 p-3'>
      <div className='policy-desc col-lg-12'>Once registered to Let's Sing Messiah (LSM) after successful payment of the registration fee , the registered member will be a confirmed participant for the event. In such a case, the amount debited for registration will not be refunded to the registered member. Hence, cancellation after registration is not entertained. In case of any queries with respect to Refunder/Cancellation policy, use the details in the 'Contact Us' section to reach us.</div>

      <h4 className='my-3'>Policy changes & revisions</h4>
      <div className='policy-desc col-lg-12'>
        Our society regulations changes from time to time and we may make amendments or changes to our policies and this notice. We may email reminders of changes in our notices and policies, unless we have received instructions not to. You are advised to visit our website frequently to stay updated.

        We will never materially change our policies to cause loss of protection of your information. If there are any questions regarding this privacy policy you may contact us using the information below:
      </div>
      <div className='mt-3'>Society Name : Coimbatore Chamber Chorale</div>
      <div>Address : 102, C1, 8th Street, Sriram Nagar, Podanur, Coimbatore, Tamilnadu, India - 641 023</div>
      <div>Telephone No: +91 9080093092</div>
      <div>E-Mail ID : coimbatorechamberchorale@gmail.com</div>
    </div>
    <Footer />
  </div>
);

export default CancellationRefundPolicy;
