import React, { useState, useEffect } from 'react';
import './Payment.css';
import apiInstance from '../../services/httpService';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../Loader/Loader';
import paymentService from '../../services/paymentService';

const Payment = () => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')

  const monthMapObj = { "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DEC" }
  const data = { token: localStorage.getItem('token') }
  const [isUserpaid, setIsUserPaid] = React.useState(false)
  const [globalPaymentStatus, setGlobalPaymentStatus] = React.useState(false)
  const [isAllConcertPaid, setIsAllConcertPaid] = React.useState(false)
  const [eventsData, setEventsData] = React.useState([])

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getPaymentInfo()
  }, []);

  const getPaymentInfo = () => {
    apiInstance.axiosCall('api/ccc/user/dashboard', data, 'POST').then((response) => {
      console.log(response.data)
      if (response.data.status == 'success') {
        localStorage.setItem('username', response.data.data.name)
        if (response.data.data.events.length > 0) {
          setEventsData(response.data.data.events)
          var confimedEventsCount = 0
          response.data.data.events.map((event) => {
            if (event.payment_details.registration_status == 'confirmed') {
              confimedEventsCount += 1
            }
            if (event.payment_details.charge_type == 'paid_concert') {
              setGlobalPaymentStatus(true)
            }
          })
          if (response.data.data.events.length == confimedEventsCount) {
            setIsAllConcertPaid(true)
          }
        }
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src

      script.onload = () => {
        resolve(true)
      }

      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  const displayRazorPay = (amount, email, phone, eventId, transactionId, orderId, eventName, eventDescription, thumbnail) => {

    const OtherReturnData = {
      eventId: eventId,
      transactionId: transactionId,
      orderId: orderId,
      amount: amount
    }

    paymentService.createRazorpayInstance(amount, eventName, eventDescription, thumbnail, email, phone, razorPayCallback, OtherReturnData);
  }

  const razorPayCallback = (response, otherData) => {
    updatePaymentInfo(response.razorpay_payment_id, otherData.amount, otherData.eventId, otherData.transactionId, otherData.orderId)
  }

  const initiatePayment = (amount, eventId, eventName, eventDescription, thumbnail) => {
    var obj = { payment_amount: amount, event_id: eventId }
    var reqData = {}
    reqData.data = obj
    reqData.token = data.token
    apiInstance.axiosCall('api/ccc/event/payment/start', reqData, 'POST').then((response) => {
      console.log(response.data)
      if (response.data.status == 'success') {
        displayRazorPay(amount, response.data.data.email, response.data.data.phone, eventId, response.data.data.transaction, response.data.data.order_id, eventName, eventDescription, thumbnail)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  const updatePaymentInfo = (payment_id, amount, eventId, transactionId, orderId) => {
    // setIsLoading(true)
    var transactionRes = {
      status: "success",
      payment_amount: amount,
      transaction_id: transactionId,
      razorpay_id: payment_id,
      event_id: eventId,
      order_id: orderId
    }
    var reqData = {}
    reqData.data = transactionRes
    reqData.token = data.token
    // console.log(transactionRes)
    apiInstance.axiosCall('api/ccc/event/payment/complete', reqData, 'POST').then((response) => {
      console.log(response.data)
      // setIsLoading(false)
      if (response.data.status == 'success') {
        getPaymentInfo()
        setSeverity('success')
        setMessage("Payment done for the event.")
        setOpen(true)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className='row col-lg-12 m-0 px-4'>
      <div className='row col-lg-10 col-12 mx-auto my-3 px-0'>
        <div className='more-updates-section'>Check the 'Resources' tab to access scores and practice materials.</div>
      </div>
      <div className="payment-section row col-lg-10 mx-auto">
        <div className='payment-label col-lg-12 d-flex align-items-center justify-content-between'>
          <div>Enrolled Events</div>
          {
            globalPaymentStatus ?
              <div className='payment-status-badge d-flex align-items-center'><div className={isAllConcertPaid ? 'payment-status-indicator status-green' : 'payment-status-indicator status-red'}></div>{isAllConcertPaid ? 'All Events Paid' : 'Payment Pending'}</div>
              :
              ''
          }
        </div>

        <div className='row event-item-row'>

          {/* Start Rendering the upcoming events recieved via props */}
          {
            eventsData?.map((event) => {
              return (
                <div className='col-lg-4 my-3 event-item-wrapper' key={'event-' + event.event_id}>
                  <div className='event-item'>
                    <img className='event-img' src={event.event_thumbnail ? event.event_thumbnail : require("../../assets/Ladies.jpg")} />
                    <div className='row event-info p-3'>
                      <div className='col-lg-3 col-3 text-center mb-3'>
                        <h3 className='m-0 mt-1 event-month'>{monthMapObj[event.date_details.date.split('/')[1]]}</h3>
                        {
                          event.date_details.dummy_date == false ?
                            <h3 className='m-0 event-date'>{event.date_details.date.split('/')[0]}</h3>
                            :
                            ''
                        }
                        <h3 className='m-0 mt-1 event-year'>{event.date_details.date.split('/')[2]}</h3>
                      </div>
                      <div className='col-lg-9 col-9'>
                        <h5 className='event-title' title={event.name}>{event.display_name}</h5>
                        {
                          event.payment_details.charge_type != 'paid_concert' ?
                            <div className='pay-btn'>Free</div>
                            :
                            ''
                        }
                        {
                          event.payment_details.registration_status != 'confirmed' && event.payment_details.charge_type == 'paid_concert' ?
                            <div className='pay-btn' onClick={() => initiatePayment(event.payment_details.registration_fee, event.event_id, event.display_name, event.description, event.event_thumbnail)}>Pay ₹ {event.payment_details.registration_fee}</div> :
                            ''
                        }
                        {
                          event.payment_details.registration_status == 'confirmed' && event.payment_details.charge_type == 'paid_concert' ?
                            <div className='pay-btn paid-btn'>Payment Done</div> :
                            ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            )
          }
          {/* End Rendering the upcoming events recieved via props */}

          {
            eventsData.length == 0 ? <div className='col-lg-12 my-3'><div className='no-events-found col-lg-12'>No Events Found.</div></div> : ''
          }

        </div>

      </div>
      {/* Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: 'fit-content' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
};

export default Payment;
