import React, { useEffect } from 'react';
import './App.css';
import Home from './components/Home/Home';
import Events from './components/Events/Events'
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import Blogs from './components/Blogs/Blogs';
import Testimonials from './components/Testimonials/Testimonials';
import Store from './components/Store/Store';
import About from './components/About/About';
import Media from './components/Media/Media';
import Quotes from './components/Quotes/Quotes';
import Register from './components/Register/Register';
import Loader from './components/Loader/Loader';

import apiInstance from './services/httpService';

function App() {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [eventsData, setEventsData] = React.useState([])
  useEffect(() => {
    apiInstance.axiosCall('/api/ccc/events/public', null, 'GET').then((response) => {
      // console.log(response.data.concerts.upcoming)
      // console.log(response.data.concerts.upcoming)
      setEventsData(response.data.concerts.upcoming)
      setIsLoaded(true)
    }).catch(error => {
      setIsLoaded(true)
      setEventsData([])
      console.log('::Please check internet connection::')
    })
  }, []);
  if (!isLoaded) {
    return (
      <Loader />
    );
  }
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Quotes />
      <Events eventsData={eventsData} />
      <Media />
      <Blogs />
      <Footer />
    </div>
  );
}

export default App;
