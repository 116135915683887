import React from 'react';
import './Contact.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../../services/scrollToTop';
import Footer from '../Footer/Footer';

// Lottie Imports
import Lottie from 'react-lottie-player'
import contactLottie from '../../lotties/contact.json'

const Contact = () => (
  <div className="Contact">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='contact-banner col-lg-12'>
        <h1 className='contact-banner-title mx-auto'>Contact</h1>
      </div>
    </div>


    <div className='web-layout row justify-content-center mx-0'>
      <div className='col-lg-4 px-2 d-flex flex-column justify-content-center align-items-center'>
        <div className='contact-card text-center col-lg-12 my-3'>
          <img className='mail-icon' src={require("../../assets/mail.png")} />
          <h3>coimbatorechamberchorale@gmail.com</h3>
        </div>
        <div className='contact-card text-center col-lg-12 my-3'>
          <img className='phone-icon' src={require("../../assets/phone.png")} />
          <h3>+91 9080093092</h3>
        </div>
      </div>
      {/* Contact Lottie */}
      <div className='col-lg-4'>
        <Lottie
          loop
          animationData={contactLottie}
          play
        />
      </div>
      <div className='col-lg-4 px-2 d-flex flex-column justify-content-center align-items-center'>
        <div className='contact-card text-center col-lg-12 my-3'>
          <img className='location-icon' src={require("../../assets/location.png")} />
          <h3>102, C1, 8th Street, Sriram Nagar, Podanur, Coimbatore, Tamilnadu, India - 641 023</h3>
        </div>
      </div>
    </div>

    <div className='mobile-layout row justify-content-center mx-0'>
      {/* Contact Lottie */}
      <div className='col-lg-4 col-12'>
        <Lottie
          loop
          animationData={contactLottie}
          play
        />
      </div>
      <div className='col-lg-4 col-12 px-2 d-flex flex-column justify-content-center align-items-center'>
        <div className='contact-card text-center col-lg-12 col-12 my-3'>
          <img className='mail-icon' src={require("../../assets/mail.png")} />
          <h3>coimbatorechamberchorale@gmail.com</h3>
        </div>
        <div className='contact-card text-center col-lg-12 col-12 my-3'>
          <img className='phone-icon' src={require("../../assets/phone.png")} />
          <h3>+91 9080093092</h3>
        </div>
        <div className='contact-card text-center col-lg-12 col-12 my-3'>
          <img className='location-icon' src={require("../../assets/location.png")} />
          <h3>102, C1, 8th Street, Sriram Nagar, Podanur, Coimbatore, Tamilnadu, India - 641 023</h3>
        </div>
      </div>
    </div>

    <Footer />
  </div>
);

export default Contact;
