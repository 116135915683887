import React from 'react';
import './BlogsExtended.css';
import ScrollToTop from '../../services/scrollToTop';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';

const BlogsExtended = () => (
  <div className="BlogsExtended">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='blogs-banner col-lg-12'>
        <h1 className='blogs-banner-title mx-auto'>Blogs</h1>
      </div>
    </div>
    <div className='row col-lg-12 m-0'>
      <div className='col-lg-12 my-3 px-3 blogs-item-wrapper'>
        <div className='blogs-item'>
          <img className='blogs-img' src={require("../../assets/choir2.jpg")} />
          <div className='row blogs-info mx-0 py-3 px-2'>
            <h5 className='blogs-title'>Top ten ideas to bring a soft unified sound in Choral singing</h5>
            <div className='d-flex justify-content-start my-1'>
              <p className='blogs-author m-0 my-1'>Faith Ragland |</p>
              <p className='blogs-author mx-1 my-1'>02 May 2022</p>
            </div>
            <p className='blogs-desc my-2'>Content Upcoming</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
export default BlogsExtended;
