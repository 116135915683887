import React from 'react';
import './registrationCommittee.css';
import apiInstance from '../../services/httpService';
import { EXPORT_REGISTRATION_DATA } from './url.constants';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../Loader/Loader';

const RegistrationCommittee = () => {

  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const exportData = () => {
    setIsLoading(true)
    const data = {
      token: localStorage.getItem('token')
    }
    apiInstance.axiosCall('api/ccc/lsm/registration/report', data, 'POST').then((response) => {
      console.log(response)
      const url = EXPORT_REGISTRATION_DATA
      openLink(url)
      setIsLoading(false)
      setSeverity('success')
      setMessage('Registration data downloaded.')
      setOpen(true)
    })
  }

  const openLink = (url) => {
    window.open(url, '_blank')
  }

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="registrationCommittee">
      <div className='col-lg-10 mx-auto export' onClick={() => exportData()}>
        <img src={require('../../assets/download.png')} className="resources-icon" />
        Export Registration Data
      </div>
      {/* Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: 'fit-content' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default RegistrationCommittee;
