import React from 'react';
import ScrollToTop from '../../services/scrollToTop';
import Footer from '../Footer/Footer';
import './TermsAndConditions.css';
import Navbar from '../Navbar/Navbar'

const TermsAndConditions = () => (
  <div className="TermsAndConditions">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='privacy-banner col-lg-12'>
        <h1 className='privacy-banner-title text-center mx-auto'>Terms and Conditions</h1>
      </div>
    </div>
    <div className='row col-lg-12 mx-0 p-3'>
      <p className='policy-desc col-lg-12'><b>By registering to our events through this website, you, the registered member, agree to all terms and conditions mentioned on the site. We reserve the rights to make amendments to policies without prior notice.</b></p>

      <p>1) Any user can register to the event through our offical website www.coimbatorechambarechorale.com only. The registration will be accepted only if there are vacancies to the vocal part
        that the member is choosing.</p>

      <p>2) We reserve right to reject a registration in case of any incomplete or incosistant data that is used during registration. In case if the registration is rejected, we would follow it
        up with the email as to why it is rejected.</p>

      <p>3) Once the user has paid and registered to the event, he can log in with his email id and password that he set during the registration process.</p>

      <p>4) If the user has forgotten the password, when he tries to recover the password, CCC would send an email to the registered email id to recover the password</p>

      <p>5) The user should use the dashboard area which is available to him after login, for any information about the event</p>

      <p>6) The user shall attend the online practices that are organised for the entire choir or for a section</p>

      <p>7) The user shall read all the contents of the event information and read the guidelines for the regulations</p>

      <p>8) The user shall not share is password or any of the shared resources as a part of practice with any other person.</p>

      <h4 className='my-3'>Policy changes & revisions</h4>
      <div className='policy-desc col-lg-12'>
        Our society regulations changes from time to time and we may make amendments or changes to our policies and this notice. We may email reminders of changes in our notices and policies, unless we have received instructions not to. You are advised to visit our website frequently to stay updated.

        We will never materially change our policies to cause loss of protection of your information. If there are any questions regarding this privacy policy you may contact us using the information below:
      </div>
      <div className='mt-3'>Society Name : Coimbatore Chamber Chorale</div>
      <div>Address : 102, C1, 8th Street, Sriram Nagar, Podanur, Coimbatore, Tamilnadu, India - 641 023</div>
      <div>Telephone No: +91 9080093092</div>
      <div>E-Mail ID : coimbatorechamberchorale@gmail.com</div>
    </div>
    <Footer />
  </div>
);

TermsAndConditions.propTypes = {};

TermsAndConditions.defaultProps = {};

export default TermsAndConditions;
