import React, { useEffect } from 'react';
import apiInstance from '../../services/httpService';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Attendees.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Attendees = () => {

  const [attendeesList, setAttendeesList] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalRevertOpen, setModalRevertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [curName, setCurName] = React.useState("")
  const [curId, setCurId] = React.useState("");
  const [curSubString, setCurSubString] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    getAttendeesList("");
  }, []);

  const getAttendeesList = (searchKey) => {
    if(searchKey.length < 3 && searchKey.length > 0) {
      return false
    }
    const data = {
      data: { name: searchKey },
      token: localStorage.getItem('token')
    }
    apiInstance.axiosCall('/api/ccc/lsm/direct/register', data, 'POST').then((response) => {
      console.log(response.data)
      if (response.data.status == 'success') {
        setAttendeesList(response.data.data)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  const updateAttendeeStatus = (name, id, status) => {
    setCurName(name);
    setCurId(id);
    if (status) {
      setModalOpen(true);
    } else {
      setModalRevertOpen(true);
    }
  }

  const confirmAttendeeStatus = (status) => {
    const data = {
      data: { name: curName, id: curId, checkin: status },
      token: localStorage.getItem('token')
    }
    apiInstance.axiosCall('api/ccc/lsm/direct/checkin', data, 'POST').then((response) => {
      console.log(response.data)
      if (response.data.status == 'success') {
        setSeverity('success')
        setMessage(response.data.message)
        setOpen(true)
        getAttendeesList(curSubString)
        setCurId("");
        setCurName("");
        handleModalClose();
        handleModalRevertClose();
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
        handleModalClose();
        handleModalRevertClose();
      }
    })
  }

  const handleSearch = e => {
    setCurSubString(e.target.value)
    getAttendeesList(e.target.value);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurId("");
    setCurName("");
  };

  const handleModalRevertClose = () => {
    setModalRevertOpen(false);
    setCurId("");
    setCurName("");
  };

  return (
    <div className="Attendees">
      <div className='col-lg-4 mx-auto my-4 px-2'>
        <TextField className='col-lg-12' autoComplete='name' id="outlined-basic" label="Search by Name" variant="outlined" onKeyUp={handleSearch} name='name' />
      </div>
      <div className='col-lg-10 row mx-auto'>
        {
          attendeesList.map((attendee) =>
            <div className='col-lg-4 mt-3 px-2'>
              <div className='attendees-item'>
                <h3>{attendee.name}</h3>
                <p>{attendee.vocal_part}</p>
                <p className='attendees-email mb-0'>Email: {attendee.email}</p>
                <p className='attendees-email mb-0'>City: {attendee.city}</p>
                {
                  attendee.checked_in ?
                    <div onClick={() => updateAttendeeStatus(attendee.name, attendee.id, false)} className='checkInButton red-btn mt-2'>Revert</div>
                    :
                    <div onClick={() => updateAttendeeStatus(attendee.name, attendee.id, true)} className='checkInButton green-btn mt-2'>Check-in</div>
                }
              </div>
            </div>
          )
        }
      </div>
      {/* Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Check-in"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to <b>Check-in {curName}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmAttendeeStatus(true)}>Check In</Button>
          <Button onClick={handleModalClose}>cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalRevertOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalRevertClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Revert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to <b>revert {curName}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmAttendeeStatus(false)}>Revert</Button>
          <Button onClick={handleModalRevertClose}>cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};


export default Attendees;
