import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import apiInstance from '../../services/httpService';
import MuiAlert from '@mui/material/Alert';
import './ResourceSongs.css';

const ResourceSongs = ({ changeActiveDir }) => {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [eventResourcesData, setEventResourcesData] = React.useState({})

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    let data = {
      data: {
        event_id: localStorage.getItem('resourceEventId')
      },
      token: localStorage.getItem('token')
    }
    apiInstance.axiosCall('api/ccc/user/event_resource', data, 'POST').then((response) => {
      if (response.data.status == 'success') {
        setEventResourcesData(response.data.data)
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    });
  }, []);

  const openLink = (url) => {
    window.open(url, '_blank')
  }

  const viewSongResources = (id) => {
    console.log(id)
    localStorage.setItem('resourceSongId', id)
    changeActiveDir('resourcesArea')
  }

  return (
    <div className="ResourceSongs mx-auto">
      <Breadcrumbs separator=">" aria-label="breadcrumb" className='my-3 mx-2'>
        <Link underline="hover" key="1" color="inherit" onClick={() => changeActiveDir('events')}>
          Events
        </Link>
        <Link underline="hover" key="2" color="inherit">
          Songs
        </Link>
      </Breadcrumbs>
      <div className='row col-lg-12 mx-auto px-2 my-4'>
        <h4 className='events-header-label'>Important Notes</h4>
        <div className='notes-section col-lg-12 col-12 py-3'>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Use the link mentioned below to download the score in 'pdf' format</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Please print the score in A4 sheet and bind it (You can choose your own binding style)</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Alternatively, you can also buy a paper back version of the score from Amazon. Refer to the link below for the same (Note: Do not buy any other version of the book)</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Following are the list of 15 choruses which we would be performing as a part of Let's Sing Messiah - 2023</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Click on the choruses below to access the correspoding rehearsal tracks</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Once you click the chorus, you will find two video tracks</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Use Video 2 to learn your section(part) of the chorus (your respective part will sound highlighted)</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Use Video 1 to practice the same (All 4 parts will sound balanced)</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Apart from 15 choruses, solos will also be performed</label>
          </div>
          <div className='notes-item d-flex align-items-center my-0'>
            <label>‣ Total duration of the concert will be 90 minutes (with a break inbetween)</label>
          </div>
        </div>
      </div>
      <div className='row col-lg-12 mx-auto my-4'>
        <h4 className='events-header-label'>Scores</h4>
        {
          eventResourcesData.score_list?.map((resource) =>
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-6 col-12'>
              <div className='resources-event-item' onClick={() => openLink(resource.uri)}>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon p-2' src={require('../../assets/pdf.png')} />
                  <div>
                    <label className='resources-item-label mx-3' onClick={() => openLink(resource.uri)}>{resource.display_name}</label>
                    <p className='view-label my-0 mx-3' onClick={() => openLink(resource.uri)}>View/Download</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className='row col-lg-12 mx-auto my-4'>
        <h4 className='events-header-label'>General Resources</h4>
        {
          eventResourcesData.general_resources?.map((resource) =>
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-6 col-12'>
              <div className='resources-event-item' onClick={() => openLink(resource.uri)}>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon' src={require('../../assets/download.png')} />
                  <div>
                    <label className='resources-item-label mx-3' onClick={() => openLink(resource.uri)}>{resource.display_name}</label>
                    <p className='view-label my-0 mx-3' onClick={() => openLink(resource.uri)}>View/Download</p>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className='row col-lg-12 mx-auto my-4'>
        <h4 className='events-header-label'>Songs List</h4>
        {
          eventResourcesData.song_list?.map((song) =>
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-4 col-12' key={song.song_id}>
              <div className='resources-event-item' onClick={() => viewSongResources(song.song_id)}>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon p-2' src={require('../../assets/resources.png')} />
                  <div className='song-details'>
                    <label className='resources-item-label mx-3'>{song.display_name}</label>
                    <label className='resources-item-composer mx-3'>{song.composer}</label>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>

    </div>
  )
}

export default ResourceSongs;
