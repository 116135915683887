import React from 'react';
import './Media.css';
import { Navbar, Nav, Container, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const Media = () => {
  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }
  return (
    <div className="Media">
      <div className='row m-auto d-flex align-items-center col-lg-10'>
        <div className='col-lg-7 media-desc px-3'>
          <h4 className="media-label">Media</h4>
          <p>Look through our media section for more clips of CCC</p>
          <div onClick={() => navigateTo('/media')} className='know-more-btn mt-4'>View All Media <img className='arrow-icon ml-2' src={require("../../assets/arrow-icon.png")} /></div>
        </div>
        <div className='col-lg-5 media-photos'>
          <div className='row'>
            <div className='col-lg-8 col-8 mt-3'><img src={require("../../assets/messiah_3.jpg")} /></div>
            <div className='col-lg-4 col-4 mb-3'><img src={require("../../assets/4.jpg")} /></div>
          </div>
          <div className='row mt-3'>
            <div className='col-lg-4 col-4 mt-3'><img src={require("../../assets/2.jpg")} /></div>
            <div className='col-lg-8 col-8 mb-3'><img src={require("../../assets/messiah_1.jpg")} /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
