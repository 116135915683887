import React from 'react';
import './ResourceEvents.css';

const ResourceEvents = ({ eventsData, changeActiveDir }) => {
  const viewEventSongs = (id) => {
    console.log(id)
    localStorage.setItem('resourceEventId', id)
    changeActiveDir('songs')
  }
  console.log(changeActiveDir)
  return (
    <div className="ResourceEvents">
      <h4 className='events-header-label mx-2'>Events</h4>
      {
        eventsData?.map((event) =>
          <div className="col-lg-12 col-12 px-2">
            <div className='resources-event-item-wrapper my-2 pr-4 col-lg-3 col-12' key={event.event_id}>
              <div className='resources-event-item' onClick={() => viewEventSongs(event.event_id)}>
                <div className='d-flex align-items-center'>
                  <img className='resources-icon' src={require('../../assets/resources.png')} />
                  <label className='resources-item-label mx-3'>{event.display_name}</label>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ResourceEvents;
